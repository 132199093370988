var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container fabricMaintain" },
    [
      !_vm.isEdit
        ? _c("div", [
            _c("div", { staticClass: "actions_part clearfix" }, [
              _c("div", { staticClass: "actions_wrap" }, [
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.productCode,
                        callback: function($$v) {
                          _vm.productCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "productCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("物料编号：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.materialCode,
                        callback: function($$v) {
                          _vm.materialCode =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "materialCode"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新人：")]),
                    _c("el-input", {
                      staticClass: "input_single w200 mr10",
                      attrs: { placeholder: "请输入" },
                      nativeOn: {
                        keyup: function($event) {
                          if (
                            !$event.type.indexOf("key") &&
                            _vm._k(
                              $event.keyCode,
                              "enter",
                              13,
                              $event.key,
                              "Enter"
                            )
                          ) {
                            return null
                          }
                          return _vm.searchHandle($event)
                        }
                      },
                      model: {
                        value: _vm.updateUser,
                        callback: function($$v) {
                          _vm.updateUser =
                            typeof $$v === "string" ? $$v.trim() : $$v
                        },
                        expression: "updateUser"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("产品品牌：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.getPurchaseOrderList },
                        model: {
                          value: _vm.brand,
                          callback: function($$v) {
                            _vm.brand =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "brand"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.productCodetypelist, function(item) {
                          return _c("el-option", {
                            key: item.dictItemValue,
                            attrs: {
                              value: item.dictItemValue,
                              label: item.dictItemName
                            }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("产品特性：")]),
                    _c(
                      "el-select",
                      {
                        staticClass: "select_single w200 mr10",
                        attrs: { placeholder: "请选择" },
                        on: { change: _vm.getPurchaseOrderList },
                        model: {
                          value: _vm.season,
                          callback: function($$v) {
                            _vm.season = $$v
                          },
                          expression: "season"
                        }
                      },
                      [
                        _c("el-option", {
                          key: "",
                          attrs: { label: "全部", value: "" }
                        }),
                        _vm._l(_vm.seasontypelist, function(item) {
                          return _c("el-option", {
                            key: item.index,
                            attrs: { label: item.name, value: item.index }
                          })
                        })
                      ],
                      2
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "display_ib mr10 mb10" },
                  [
                    _c("span", { staticClass: "tag" }, [_vm._v("更新日期：")]),
                    _c("el-date-picker", {
                      attrs: {
                        "unlink-panels": "",
                        type: "daterange",
                        format: "yyyy-MM-dd",
                        "value-format": "yyyy-MM-dd",
                        "range-separator": "至",
                        "start-placeholder": "开始日期",
                        "end-placeholder": "结束日期"
                      },
                      on: { change: _vm.dateValChange },
                      model: {
                        value: _vm.dateVal,
                        callback: function($$v) {
                          _vm.dateVal = $$v
                        },
                        expression: "dateVal"
                      }
                    })
                  ],
                  1
                )
              ]),
              _c(
                "div",
                { staticClass: "actions_btn_wrap down t_right" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      staticClass: "mr10",
                      attrs: {
                        type: "primary",
                        disabled: this.isLoadingExport
                      },
                      on: { click: _vm.exportHandle }
                    },
                    [_vm._v("导出")]
                  ),
                  _c(
                    "el-upload",
                    {
                      staticClass: "upload-demo inline_block mr10",
                      attrs: {
                        headers: _vm.myHeaders,
                        action: this.uploadurlProduct,
                        accept: ".xlsx",
                        "before-upload": _vm.beforeupload,
                        "show-file-list": _vm.showfilelist,
                        data: {
                          businessType: "Retail,Suits",
                          applicantName: this.$store.state.realName
                        },
                        "on-success": _vm.UploadSuccess
                      }
                    },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: {
                            type: "primary",
                            disabled: this.isLoadingImport
                          },
                          on: { click: _vm.importHandle }
                        },
                        [_vm._v("导入")]
                      )
                    ],
                    1
                  ),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-search",
                      circle: "",
                      title: "搜索"
                    },
                    on: { click: _vm.searchHandle }
                  }),
                  _c("el-button", {
                    attrs: {
                      size: "small",
                      type: "primary",
                      icon: "el-icon-plus",
                      circle: "",
                      title: "添加"
                    },
                    on: { click: _vm.addHandle }
                  })
                ],
                1
              )
            ]),
            _c(
              "div",
              { staticClass: "table_part clearfix" },
              [
                _c(
                  "el-tabs",
                  {
                    directives: [
                      {
                        name: "loading",
                        rawName: "v-loading",
                        value: _vm.tableLoading,
                        expression: "tableLoading"
                      }
                    ],
                    staticClass: "subnav_wrap",
                    staticStyle: { "min-height": "536px" }
                  },
                  [
                    _c("singleTable", {
                      attrs: {
                        tableList: _vm.orderList,
                        tableLeaderFieldsList: _vm.leaderFieldsList,
                        tableTailFieldsList: _vm.tailFieldsList,
                        tableFieldsList: _vm.fieldsList
                      },
                      on: {
                        deleteHandle: _vm.deleteHandle,
                        editHandle: _vm.editHandle,
                        cloneHandle: _vm.cloneHandle,
                        checkHandle: _vm.checkHandle
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "pagination_wrap fr" },
                  [
                    _c("el-pagination", {
                      attrs: {
                        "current-page": _vm.currentPage,
                        "page-sizes": [10, 50],
                        "page-size": _vm.pageSize,
                        layout: "sizes, total, prev, pager, next",
                        total: this.total
                      },
                      on: {
                        "size-change": _vm.handleSizeChange,
                        "current-change": _vm.handleCurrentChange,
                        "update:currentPage": function($event) {
                          _vm.currentPage = $event
                        },
                        "update:current-page": function($event) {
                          _vm.currentPage = $event
                        }
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        : _c("div", { staticClass: "form_part clearfix" }, [
            _vm.actionsType == "check"
              ? _c(
                  "div",
                  [
                    _c("fabricMaintainCheck", {
                      attrs: {
                        list: _vm.ruleForm,
                        uploadFileList: _vm.uploadFileList
                      },
                      on: { cancelHandle: _vm.resetForm }
                    })
                  ],
                  1
                )
              : _c("div", [
                  _c(
                    "div",
                    { staticClass: "base_wrap clearfix" },
                    [
                      _c("h2", { staticClass: "title" }, [
                        _vm._v("产品基本信息")
                      ]),
                      _c(
                        "el-form",
                        {
                          ref: "ruleForm",
                          staticClass: "demo-form-inline",
                          attrs: {
                            inline: true,
                            model: _vm.ruleForm,
                            rules: _vm.rules,
                            "label-width": "200px"
                          }
                        },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品编号" } },
                            [
                              _c("el-input", {
                                attrs: { disabled: this.actionsType == "edit" },
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatProductClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupProductCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.productCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productCode"
                                }
                              }),
                              this.actionsType == "edit"
                                ? _c("el-button", {
                                    staticClass: "el-icon-edit",
                                    staticStyle: { "margin-left": "20px" },
                                    attrs: {
                                      type: "primary",
                                      circle: "",
                                      size: "mini",
                                      title: "更新"
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.popoverHandle(_vm.ruleForm)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          ),
                          _c("el-form-item", { attrs: { label: "系统编号" } }, [
                            _c("span", [
                              _vm._v(_vm._s(_vm.ruleForm.interiorCode))
                            ])
                          ]),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品条形码" } },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatProductBarcodeClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupProductBarcode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.productBarcode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productBarcode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productBarcode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品名称" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.productName,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productName",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productName"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "物料编号", prop: "materialCode" }
                            },
                            [
                              _c("el-input", {
                                on: {
                                  change: function($event) {
                                    return _vm.hasRepeatMeterialClick(
                                      _vm.ruleForm
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    return _vm.onkeyupMaterialCode($event)
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.materialCode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "materialCode",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.materialCode"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品描述" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.specification,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "specification",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.specification"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "业务分类", prop: "businessType" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.businessType,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "businessType",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.businessType"
                                  }
                                },
                                _vm._l(this.busiCategorytypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品品牌", prop: "brand" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.brand,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "brand",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.brand"
                                  }
                                },
                                _vm._l(_vm.productCodetypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.dictItemValue,
                                    attrs: {
                                      value: item.dictItemValue,
                                      label: item.dictItemName
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品成分",
                                prop: "productComposition"
                              }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.productComposition,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productComposition",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productComposition"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "产品属性", prop: "productClass" }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.productClass,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productClass",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productClass"
                                  }
                                },
                                _vm._l(this.ProductClasstypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品系列" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.productSeries,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "productSeries",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.productSeries"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "品类", prop: "category" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.category,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "category",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.category"
                                  }
                                },
                                _vm._l(this.categorytypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品特性", prop: "season" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.season,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "season",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.season"
                                  }
                                },
                                _vm._l(this.seasontypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "产品区分" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.productDistinguish,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productDistinguish",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productDistinguish"
                                  }
                                },
                                _vm._l(this.distinguishtypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "销售计量单位",
                                prop: "saleMeasurementUnit"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.saleMeasurementUnit,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "saleMeasurementUnit",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.saleMeasurementUnit"
                                  }
                                },
                                _vm._l(this.SaleUnittypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "时尚度" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.fashion,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "fashion",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.fashion"
                                  }
                                },
                                _vm._l(
                                  this.ProductFashionDegreetypelist,
                                  function(item) {
                                    return _c("el-option", {
                                      key: item.index,
                                      attrs: {
                                        value: item.index,
                                        label: item.name
                                      }
                                    })
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "延米克重", required: "" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.weightPerMeterOne = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterOne
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.weightPerMeterOne = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterOne
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.weightPerMeterOne,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "weightPerMeterOne",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.weightPerMeterOne"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [_vm._v("  -  ")]),
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.weightPerMeterTwo = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterTwo
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.weightPerMeterTwo = _vm.oninput(
                                          _vm.ruleForm.weightPerMeterTwo
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.weightPerMeterTwo,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "weightPerMeterTwo",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.weightPerMeterTwo"
                                    }
                                  })
                                ],
                                1
                              ),
                              _vm._v("\n                克\n               "),
                              _c("span", { staticClass: "red note" }, [
                                _vm._v(
                                  "注:区间值填写两个值,固定值任意填写一个文本框即可"
                                )
                              ])
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "销售季", prop: "saleSeasou" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.saleSeasou,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "saleSeasou",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.saleSeasou"
                                  }
                                },
                                _vm._l(this.SaleSeasoutypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.dictItemValue,
                                    attrs: {
                                      value: item.dictItemValue,
                                      label: item.dictItemName
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "每平米克重" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "12" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.weightPerSquareMeter = _vm.oninput(
                                      _vm.ruleForm.weightPerSquareMeter
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.weightPerSquareMeter = _vm.oninput(
                                      _vm.ruleForm.weightPerSquareMeter
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.weightPerSquareMeter,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "weightPerSquareMeter",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.weightPerSquareMeter"
                                }
                              }),
                              _vm._v("  克\n            ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "颜色" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.color,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "color",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.color"
                                  }
                                },
                                _vm._l(this.colortypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "每个销售单位的物料数量" } },
                            [
                              _c("el-input", {
                                attrs: { maxlength: "12" },
                                on: {
                                  change: function($event) {
                                    _vm.ruleForm.numbersPerMeasurementUnit = _vm.oninput(
                                      _vm.ruleForm.numbersPerMeasurementUnit
                                    )
                                  }
                                },
                                nativeOn: {
                                  keyup: function($event) {
                                    _vm.ruleForm.numbersPerMeasurementUnit = _vm.oninput(
                                      _vm.ruleForm.numbersPerMeasurementUnit
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.ruleForm.numbersPerMeasurementUnit,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "numbersPerMeasurementUnit",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression:
                                    "ruleForm.numbersPerMeasurementUnit"
                                }
                              }),
                              _vm._v("  \n            ")
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "花型" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.pattern,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "pattern",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.pattern"
                                  }
                                },
                                _vm._l(this.patterntypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "幅宽" } },
                            [
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.widthStart = _vm.oninput(
                                          _vm.ruleForm.widthStart
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.widthStart = _vm.oninput(
                                          _vm.ruleForm.widthStart
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.widthStart,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "widthStart",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.widthStart"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c("span", [_vm._v("  -  ")]),
                              _c(
                                "el-form-item",
                                { staticClass: "limit" },
                                [
                                  _c("el-input", {
                                    staticClass: "w85 unlimit",
                                    on: {
                                      change: function($event) {
                                        _vm.ruleForm.widthEnd = _vm.oninput(
                                          _vm.ruleForm.widthEnd
                                        )
                                      }
                                    },
                                    nativeOn: {
                                      keyup: function($event) {
                                        _vm.ruleForm.widthEnd = _vm.oninput(
                                          _vm.ruleForm.widthEnd
                                        )
                                      }
                                    },
                                    model: {
                                      value: _vm.ruleForm.widthEnd,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "widthEnd",
                                          typeof $$v === "string"
                                            ? $$v.trim()
                                            : $$v
                                        )
                                      },
                                      expression: "ruleForm.widthEnd"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产地",
                                prop: "placeOfProduction"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.placeOfProduction,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "placeOfProduction",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.placeOfProduction"
                                  }
                                },
                                _vm._l(this.placeProductiontypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "纱支", prop: "yarnCount" } },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.yarnCount,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "yarnCount",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.yarnCount"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "上市日期", prop: "validDate" } },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsStart,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.validDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "validDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.validDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "生命周期", prop: "invalidDate" }
                            },
                            [
                              _c("el-date-picker", {
                                attrs: {
                                  type: "date",
                                  format: "yyyy-MM-dd",
                                  "value-format": "yyyy-MM-dd",
                                  "picker-options": _vm.pickerOptionsEnd,
                                  placeholder: "选择日期"
                                },
                                model: {
                                  value: _vm.ruleForm.invalidDate,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "invalidDate",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.invalidDate"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "推荐替代品", prop: "substitute" }
                            },
                            [
                              _c("el-input", {
                                model: {
                                  value: _vm.ruleForm.substitute,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.ruleForm,
                                      "substitute",
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                    )
                                  },
                                  expression: "ruleForm.substitute"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "团购推荐指数" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.suggestIndex,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "suggestIndex",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.suggestIndex"
                                  }
                                },
                                _vm._l(this.suggestIndexTypelist, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "产品状态",
                                prop: "productStatus"
                              }
                            },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "请选择" },
                                  model: {
                                    value: _vm.ruleForm.productStatus,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "productStatus",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.productStatus"
                                  }
                                },
                                _vm._l(this.statustypelist, function(item) {
                                  return _c("el-option", {
                                    key: item.index,
                                    attrs: {
                                      value: item.index,
                                      label: item.name
                                    }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            { attrs: { label: "是否活跃" } },
                            [
                              _c(
                                "el-select",
                                {
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.ruleForm.active,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.ruleForm,
                                        "active",
                                        typeof $$v === "string"
                                          ? $$v.trim()
                                          : $$v
                                      )
                                    },
                                    expression: "ruleForm.active"
                                  }
                                },
                                _vm._l(this.ifStylelist, function(i) {
                                  return _c("el-option", {
                                    key: i.ifbatch,
                                    attrs: { value: i.ifbatch, label: i.name }
                                  })
                                }),
                                1
                              )
                            ],
                            1
                          ),
                          _c("h2", { staticClass: "title" }, [
                            _vm._v("面料产品图文信息")
                          ]),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "photo",
                              attrs: { label: "缩略图", prop: "thumbImg" }
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  attrs: {
                                    headers: _vm.headers,
                                    action:
                                      _vm.uploadUrl +
                                      "/infrastructure/v1/attachments/upload",
                                    "on-success": _vm.handleUploadSuccess,
                                    "before-upload": _vm.beforeUploadUpload,
                                    "list-type": "picture-card",
                                    limit: 10,
                                    "on-exceed": _vm.handleExceed,
                                    "file-list": _vm.uploadFileList,
                                    data: {
                                      uuid: _vm.ruleForm.uuid,
                                      type: _vm.fileType
                                    },
                                    "auto-upload": true
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "file",
                                      fn: function(ref) {
                                        var file = ref.file
                                        return _c("div", {}, [
                                          _c("img", {
                                            staticClass:
                                              "el-upload-list__item-thumbnail",
                                            attrs: { src: file.url, alt: "" }
                                          }),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "el-upload-list__item-actions"
                                            },
                                            [
                                              !_vm.disabled
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "el-upload-list__item-delete",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.handleUploadRemove(
                                                            file,
                                                            _vm.uploadFileList,
                                                            _vm.index
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "el-icon-delete"
                                                      })
                                                    ]
                                                  )
                                                : _vm._e()
                                            ]
                                          )
                                        ])
                                      }
                                    }
                                  ])
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-plus",
                                    attrs: { slot: "default" },
                                    slot: "default"
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "el-upload__tip",
                                      attrs: { slot: "tip" },
                                      slot: "tip"
                                    },
                                    [_vm._v("支持扩展名：jpg、png、gif、jpeg")]
                                  )
                                ]
                              ),
                              _c(
                                "el-dialog",
                                {
                                  attrs: { visible: _vm.dialogVisible },
                                  on: {
                                    "update:visible": function($event) {
                                      _vm.dialogVisible = $event
                                    }
                                  }
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      width: "100%",
                                      src: _vm.dialogImageUrl,
                                      alt: ""
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "el-form-item",
                            {
                              staticClass: "last",
                              attrs: {
                                label: "面料产品详细信息",
                                prop: "remarks"
                              }
                            },
                            [
                              _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  autosize: { minRows: 8, maxRows: 4 },
                                  placeholder: "多行输入",
                                  maxlength: "1000",
                                  "show-word-limit": ""
                                },
                                model: {
                                  value: _vm.ruleForm.remarks,
                                  callback: function($$v) {
                                    _vm.$set(_vm.ruleForm, "remarks", $$v)
                                  },
                                  expression: "ruleForm.remarks"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "mt20 fr" },
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "mr10",
                          on: {
                            click: function($event) {
                              return _vm.resetForm()
                            }
                          }
                        },
                        [_vm._v("取消")]
                      ),
                      _c(
                        "el-button",
                        {
                          directives: [
                            {
                              name: "preventReClick",
                              rawName: "v-preventReClick"
                            }
                          ],
                          attrs: {
                            type: "primary",
                            loading: this.loadingcommit
                          },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("ruleForm")
                            }
                          }
                        },
                        [_vm._v("保存")]
                      )
                    ],
                    1
                  )
                ])
          ]),
      _c(
        "div",
        { staticClass: "popup_part" },
        [
          _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: _vm.popupTitle,
                "close-on-click-modal": false,
                visible: _vm.dialogpopupVisible
              },
              on: {
                close: _vm.closeDialog,
                "update:visible": function($event) {
                  _vm.dialogpopupVisible = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "display_ib mr10 mb10" },
                [
                  _c("span", { staticClass: "tag" }, [_vm._v("产品编号：")]),
                  _c("el-input", {
                    staticClass: "input_single w200 mr10",
                    attrs: { placeholder: "请输入" },
                    model: {
                      value: _vm.ruleForm.productCode,
                      callback: function($$v) {
                        _vm.$set(
                          _vm.ruleForm,
                          "productCode",
                          typeof $$v === "string" ? $$v.trim() : $$v
                        )
                      },
                      expression: "ruleForm.productCode"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  staticClass: "table_list",
                  attrs: { data: _vm.popoverslist, border: "", fit: "" }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "系统名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n                  " +
                                _vm._s(row.systemName) +
                                "\n              "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "产品编号" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.productCode) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "单据名称" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.tableNameCh) +
                                " \n          "
                            )
                          ]
                        }
                      }
                    ])
                  }),
                  _c("el-table-column", {
                    attrs: { label: "记录数" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(row.recordNum) +
                                "\n          "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function($event) {
                          return _vm.cancelpopoverHandle()
                        }
                      }
                    },
                    [_vm._v("取消")]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        { name: "preventReClick", rawName: "v-preventReClick" }
                      ],
                      on: {
                        click: function($event) {
                          return _vm.savepopoverHandle()
                        }
                      }
                    },
                    [_vm._v("保存")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "提示",
            visible: _vm.dialogdelVisible,
            "close-on-click-modal": false,
            width: "30%"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogdelVisible = $event
            }
          }
        },
        [
          _c("span", [_vm._v("确定删除该条面料产品数据?")]),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogdelVisible = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  directives: [
                    { name: "preventReClick", rawName: "v-preventReClick" }
                  ],
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.delVisibleclick()
                    }
                  }
                },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ]
      ),
      _vm.dialogVisible
        ? _c("Dialog", {
            attrs: {
              dialogDesc: _vm.dialogDesc,
              dialogVisible: _vm.dialogVisible
            },
            on: {
              dialogConfirmHandle: _vm.dialogConfirmHandle,
              dialogCancelHandle: _vm.dialogCancelHandle
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }